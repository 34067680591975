@import "./assets/styles/theming.scss";

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

app-root {
  display: block;
  height: 100%;
  background: #ffc000; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #5a5a5a, #ffc000); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #5a5a5a,
    #ffc000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
}

.mat-table {
  width: 100%;
}

.mat-header-cell {
  text-align: center !important;
  justify-content: center !important;
}

.mat-cell.ctr {
  text-align: center;
  justify-content: center;
}

.mat-cell.izq {
  text-align: start;
  justify-content: start;
}

.mat-cell.der {
  text-align: end;
  justify-content: end;
}
