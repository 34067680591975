@import "~@angular/material/theming";
@include mat-core();

/*#region Palettes*/
$sph-blue: (
  50: #c9ddef,
  100: #8db7de,
  200: #5a5a5a,
  300: #5a5a5a,
  400: #5a5a5a,
  500: #5a5a5a,
  600: #5a5a5a,
  700: #5a5a5a,
  800: #5a5a5a,
  900: #5a5a5a,
  A100: #b5d8f9,
  A200: #56a6f2,
  A400: #5a5a5a,
  A700: #5a5a5a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$sph-yellow: (
  50: #ffffff,
  100: #d3d3d2,
  200: #858483,
  300: #9b9b9a,
  400: #81817f,
  500: #585857,
  600: #50504f,
  700: #57554e,
  800: #7c7b76,
  900: #70706f,
  A100: #ffffff,
  A200: #7e7d7b,
  A400: #636261,
  A700: #696968,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$sph-red: (
  50: #ffdfdf,
  100: #ff9393,
  200: #ff5b5b,
  300: #ff1313,
  400: #f40000,
  500: #d50000,
  600: #b60000,
  700: #980000,
  800: #790000,
  900: #5b0000,
  A100: #ffd5d5,
  A200: #ff6f6f,
  A400: #ff0909,
  A700: #ee0000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
/*#endregion RegionPalettes*/

$app-primary: mat-palette($sph-blue);
$app-accent: mat-palette($sph-yellow);
$app-warn: mat-palette($sph-red);

$light-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

$dark-theme: mat-dark-theme($app-primary, $app-accent, $app-warn);

.light-theme {
  @include angular-material-theme($light-theme);
}

.dark-theme {
  @include angular-material-theme($dark-theme);
}

/*#region General*/
html,
body {
  margin: 0;
  padding: 0;
}

.fondoPrimario {
  background-color: mat-color($app-primary);
}

.fuentePrimaria {
  color: mat-contrast($app-primary, A700);
}

.bordePrimario {
  border: 4px solid mat-color($app-primary);
}

.spacer {
  flex: 1 1 auto;
}

.full-width-table {
  width: 100%;
}

.mat-tooltip {
  font-size: 1em !important;
}

.mat-card-header-text,
.mat-card-title {
  width: 100% !important;
}

.mat-card-title {
  width: 100% !important;
  display: flex !important;
}

.mat-catalog-card {
  margin: 1em auto;
}

.mat-catalog-card-fill {
  height: calc(100vh - 64px - 2em);
  min-height: calc(100vh - 64px - 2em);
  display: flex !important;
  flex-direction: column !important;
}

.form-mat-catalog-card {
  width: 700px;
}

.content {
  box-sizing: border-box;
  padding: 10px;
}

.contenedor-tabla {
  width: 100%;
  overflow-x: auto;
}

.form-container {
  width: 800px;
}

.form-container-large {
  width: 90%;
  height: 95vh;
}

.hg100 {
  height: 100%;
}

.overflow-auto {
  overflow: auto !important;
}
/*#region tabla despegable*/

tr.detail-row {
  height: 0 !important;
}

tr.element-row:not(.expanded-row):hover {
  background: #f5f5f5;
}

tr.element-row:not(.expanded-row):active {
  background: #efefef;
}

.element-row td {
  border-bottom-width: 0 !important;
}

.row-detail {
  overflow: hidden;
  display: flex;
}

.element-symbol {
  font-weight: bold;
  font-size: 40px;
  line-height: normal;
}

.element-description {
  padding-bottom: 10px;
}

.element-description-attribution {
  opacity: 0.5;
}

.maticonCustom {
  color: #858483;
}

.h3Custom {
  color: #5a5a5a; /* fallback for old browsers */
  color: -webkit-linear-gradient(to right, #5a5a5a, #ffc000); /* Chrome 10-25, Safari 5.1-6 */
  color: linear-gradient(
    to right,
    #5a5a5a,
    #ffc000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/*#endregion tabla despegable*/

@media only screen and (max-width: 600px) {
  .mat-catalog-card {
    width: 95%;
  }
  .form-mat-catalog-card {
    width: 95%;
  }

  .form-container {
    width: 95%;
  }
}

@media only screen and (min-width: 600px) {
  .mat-catalog-card {
    width: 90%;
  }
}

@media only screen and (min-width: 768px) {
  .mat-catalog-card {
    width: 80%;
  }
}

@media only screen and (min-width: 992px) {
  .mat-catalog-card {
    width: 80%;
  }
}

@media only screen and (min-width: 1200px) {
  .mat-catalog-card {
    width: 1100px;
  }

  .form-container-large {
    width: 1100px;
    height: 95vh;
  }
}
/*#endregion General*/

::ng-deep .mat-form-field-flex > .mat-form-field-infix {
  padding: 0.4em 0px !important;
}
::ng-deep .mat-form-field-label-wrapper {
  top: -1.5em;
}

::ng-deep
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  transform: translateY(-1.1em) scale(0.75);
  width: 133.33333%;
}
